// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "@hotwired/hotwire-native-bridge"
import "@rails/actiontext"
import "trix"

import "./controllers/index.js"

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
